<template>
  <div class="member-ads-management">
    <div
      class="d-flex align-items-center text-placeholder my-2 cursor-pointer"
      @click="handleBackToTab"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        style="margin-right: 8px"
      />
      {{ $t('partnerView.textBackToTab', {tab: tab === "product_links" ? $t('referralProgram.memberProductLinksTitle') : $t('referralProgram.accountManagementPageTitle')}) }}
    </div>
    <div class="bg-white top-content">
      <div class="title-content">
        <p class="font-20 m-0">
          {{ $t('partnerView.titleMemberInformation') }}
        </p>
      </div>
      <div
        v-if="memberDetail"
        class="mb-1 member-info"
      >
        <div class="d-flex align-items-center mb-1">
          <b-img
            rounded
            class="ads-avt-img"
            :src="require('@/assets/images/common/ic-member-manager.svg')"
          />
          <div class="d-flex flex-column">
            <p class="text-primary font-weight-600 mb-0">
              {{ `${fullName} - ${$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelPhoneNumber')}: ${showPhoneNumber}` }}
            </p>
            <span>{{ email }}</span>
          </div>
        </div>
        <div class="d-md-flex align-items-center mb-1">
          <div>
            <p
              v-if="facebookContact"
              class="text-primary font-weight-600 mb-0 contact-facebook"
            >
              Facebook: <span
                class="cursor-pointer text-link link-facebook"
                @click="goToUrl(facebookContact)"
              >{{ facebookContact }}</span>
            </p>
          </div>
          <div
            v-if="(facebookContact && whatsAppContact) || (telegramContact && facebookContact)"
            class="line-vertical"
          />
          <div>
            <p
              v-if="whatsAppContact"
              class="text-primary font-weight-600 mb-0"
            >
              {{ `WhatsApp: ${whatsAppContact}` }}
            </p>
          </div>
          <div
            v-if="whatsAppContact && telegramContact"
            class="line-vertical"
          />
          <div>
            <p
              v-if="telegramContact"
              class="text-primary font-weight-600 mb-0"
            >
              {{ `Telegram: ${telegramContact}` }}
            </p>
          </div>
        </div>
        <div class="d-md-flex align-items-center">
          <div class="id-member">
            <p class="m-0">
              ID: <span class="font-weight-600">{{ localUserId }}</span>
            </p>
          </div>
          <div
            v-if="countryMember"
            class="line-vertical"
          />
          <div
            v-if="countryMember"
            class="id-member mt-1 mt-md-0"
          >
            <p class="m-0">
              {{ $t('stepGuideControl.stepVerifyInfo.labelCountry') }}: <span class="font-weight-600">{{ countryMember }}</span>
            </p>
          </div>
          <div
            v-if="currentPlan"
            class="line-vertical"
          />
          <div
            v-if="currentPlan"
            class="id-member mt-1 mt-md-0"
          >
            <p class="m-0">
              {{ $t('partnerView.textCurrentPlan') }}:
              <span class="font-weight-600">{{ currentPlan }}</span>
            </p>
          </div>
          <div
            v-if="planStatus"
            class="line-vertical"
          />
          <div
            v-if="planStatus"
            class="id-member plan-status mt-1 mt-md-0"
          >
            <p class="m-0">
              {{ $t('partnerView.textPlanStatus') }}:
              <span
                class="font-weight-600 status"
                :class="stylePlanStatus(planStatus)"
              >{{ multiStatusTxt(planStatus) }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="table-member-ads bg-white">
      <div class="tab-list-ads">
        <b-tabs>
          <b-tab :active="tab === 'ads_account'">
            <template #title>
              <span class="tab-title">{{ $t('partnerView.tabListAdAccount') }}</span>
            </template>
            <tab-list-ads-account :error-get-list-ads="errorGetListAds" />
          </b-tab>

          <b-tab
            v-if="hasAcl"
            :active="tab === 'product_links'"
          >
            <template #title>
              <span class="tab-title">{{ $t('partnerView.tabListProductLinks') }}</span>
            </template>
            <tab-list-product-link />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { BImg, BTabs, BTab } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { PLAN_STATUS } from '@/constants'
import partnerMixin from '@/mixins/partnerMixin'
import { toastification } from '@core/mixins/toast'
import TabListAdsAccount from './TabListAdsAccount.vue'
import TabListProductLink from './TabListProductLink.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BImg,
    BTabs,
    BTab,
    TabListAdsAccount,
    TabListProductLink,
  },
  mixins: [partnerMixin, toastification],

  data() {
    return {
      tab: this.$route.query.tab || 'ads_account',

      errorGetListAds: '',
    }
  },

  computed: {
    ...mapGetters(['memberAds', 'memberDetail', 'loading', 'message', 'status']),

    userIp() {
      return _get(this.memberDetail, ['signupTracking', 'ip'], '')
    },

    countryMember() {
      return _get(this.memberDetail, ['signupTracking', 'country'], '')
    },

    localUserId() {
      return this.memberDetail?.localUserId
    },

    email() {
      return this.memberDetail?.email
    },

    fullName() {
      return this.memberDetail?.fullName
    },

    currentPlan() {
      return _get(this.memberDetail, ['plans', 'type'], '')
    },

    planStatus() {
      return _get(this.memberDetail, ['plans', 'subscription', 'status'], '')
    },

    stylePlanStatus() {
      const status = {
        [PLAN_STATUS.INCOMPLETE]: 'incomplete',
        [PLAN_STATUS.TRIALING]: 'trialing',
        [PLAN_STATUS.ACTIVE]: 'active',
        [PLAN_STATUS.PAST_DUE]: 'past_due',
        [PLAN_STATUS.CANCELED]: 'canceled',
      }

      return val => status[val]
    },

    multiStatusTxt() {
      const status = {
        [PLAN_STATUS.INCOMPLETE]: this.$t('partnerView.statusIncomplete'),
        [PLAN_STATUS.TRIALING]: this.$t('partnerView.statusTrialing'),
        [PLAN_STATUS.ACTIVE]: this.$t('stepGuideControl.textActive'),
        [PLAN_STATUS.PAST_DUE]: this.$t('partnerView.statusPastDue'),
        [PLAN_STATUS.CANCELED]: this.$t('partnerView.statusCanceled'),
      }

      return val => status[val]
    },

    showPhoneNumber() {
      let result = ''
      const { contactInfo } = this.memberDetail
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      }
      return result
    },

    facebookContact() {
      return _get(this.memberDetail, ['contactInfo', 'facebook'])
    },
    whatsAppContact() {
      return _get(this.memberDetail, ['contactInfo', 'whatsApp'])
    },
    telegramContact() {
      return _get(this.memberDetail, ['contactInfo', 'telegram'])
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val) {
          this.tab = val?.query?.tab || 'ads_account'
        }
      },
    },
  },

  created() {
    const { params } = this.$route
    this.fetchMemberAdsManagement(params)
    this.fetchMemberDetail(params)
  },

  methods: {
    ...mapActions(['getListMemberAds', 'getMemberDetail']),

    handleBackToTab() {
      if (this.tab === 'product_links') {
        this.$router.push('/partner-management-product-link')
      } else {
        this.$router.push('/partner-management-member')
      }
    },

    async fetchMemberAdsManagement({ id }) {
      const params = {
        id,
        page: 1,
        size: 10,
      }
      await this.getListMemberAds(params)
      if (!this.status) {
        this.errorGetListAds = this.message
      }
    },

    async fetchMemberDetail(params) {
      await this.getMemberDetail(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss">
.member-ads-management {
  .nav-tabs {
    margin-bottom: 24px;
    padding-top: 1rem;

    .nav-link {
      padding: 0px;
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-member-ads {
  padding: 6px 24px 16px;
  border-radius: var(--border-radius-base);
}
.top-content {
  border-radius: var(--border-radius-base);
  margin-bottom: 24px;
  .title-content {
    border-bottom: 1px solid #16213e1a;
    padding: 16px 24px;
  }

  .member-info {
    padding: 24px;
  }
}

.line-vertical {
  display: inline-block;
  border-right: 2px solid #16213e1a;
  border-bottom: none;
  width: 3px;
  height: 32px;
  margin: 0 48px;

  @media(max-width: 767px) {
    display: none;
  }
}

.plan-status {
  .status {
    padding: 6px 14.5px;
    border-radius: 100px;
    margin-left: .5rem;
    text-transform: capitalize;
    &.active {
      background: #1db9c31a;
      color: #1db9c3;
    }

    &.canceled {
      background: #e11c4e1a;
      color: #e11c4e;
    }

    &.incomplete {
      background: #7366f01a;
      color: #7366f0;
    }

    &.trialing {
      background: #29c76f1a;
      color: #29c76f;
    }

    &.past_due {
      background: #ff9e431a;
      color: #ff9e43;
    }
  }
}

.contact-facebook{
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-link{
  color: #3271ff;

  &:hover{
    text-decoration: underline;
  }
}
</style>
